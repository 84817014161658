<template>
  <div class="app-header">
    <popupSign :want-to-make-offer="activeLogin" @oga="retourLogin"/>
    <logOut :want-to-make-offer="activDefile" @oga="retourLogOut"/>
    <popupAgence :want-to-make-offer="activeAgence" @oga="retourAgence"/>
    <div class="web-header w-full">
      <div class="w-full h-full header-container shadow flex">
        <div
          class="flex-one w-auto pl-4 flex items-center logo-section h-full"
          @click="accueil"
        >
<!--          <svg-icon-->
<!--            name="ic_logo"-->
<!--            class="md:h-12 cursor-pointer"-->
<!--            original-->
<!--          />-->
          <img src="../../assets/images/png/logo-oremi.png" alt="" class="md:h-12 w-full cursor-pointer">
        </div>

        <div class="flex-one w-4/5  h-full option-list  items-center flex justify-end mr-4">

          <div
            class="flex-two text-xl action cursor-pointer font-normal flex mr-6 items-center"
            @click="actualite"
          >
            <svg-icon
              name="ic_help"
              class="h-4 mr-3"
              original
              color="#006FB1"
            />
            <div class="">
              Aide
            </div>
          </div>

          <div
            class="flex-two text-xl action cursor-pointer font-normal flex mr-6 items-center"
            @click="faq"
          >
            <svg-icon
              name="ic_feed"
              class="h-4 mr-3"
              original
              color="#006FB1"
            />
            <div class="">
              FAQ
            </div>
          </div>

          <div
            class="flex-two text-xl action cursor-pointer font-normal flex mr-6 items-center"
            @click="contact"
          >
            <svg-icon
              name="ic_phone"
              class="h-4 w-4 mr-3 ico"
              original
              color="#006FB1"
            />
            <div class="">
              Contact
            </div>
          </div>

          <div
            class="button flex items-center"
            @click="login()"
            v-if="isLog === null"
          >
            <svg-icon
              name="ic_user"
              class="h-4 user"
              original
            />
            <div class="pl-2 pr-2 text-white">
              Se connecter
            </div>
          </div>

          <div class="" v-if="isLog !== null && broker === null">
<!--            <div class="text-center">-->
<!--              <span v-if="user.fullName.length > 2">{{user.fullName}}</span>-->
<!--              <span v-if="user.fullName.length < 2">{{user.username}}</span>-->
<!--            </div>-->
            <div class="flex items-center">
              <div
                class="button flex items-center mr-1 premier"
              >
                <svg-icon
                  name="user"
                  class="h-6"
                  original
                  @click="gonext('/home')"
                />

                <div class="pl-2 pr-2 text-white"  @click="gonext('/home')">
                  Mon espace
                </div>
              </div>

              <div class="button second" @click="defile">
                <svg-icon
                  name="chevron-down"
                  class="h-4"
                  original
                  color="white"
                />
              </div>
            </div>
          </div>

          <div class="flex items-center" v-if="isLog !== null && broker !== null">
            <div
              class="button flex items-center premier mr-1"
            >
              <div class="pl-2 pr-2 text-white"  @click="gonext('/home')">
               {{broker.label}}
              </div>
            </div>

            <div class="button second" @click="defile">
              <svg-icon
                name="chevron-down"
                class="h-4"
                original
                color="white"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-header h-24 fixed w-full shadow">
      <div class="pt-3 pb-3 w-full h-full header-container flex">
        <div class="flex-one w-2/3 pl-4 logo-section flex items-center h-full">

          <img src="../../assets/icons/ic_logo.svg" alt="" class="h-8" @click="accueil">

        </div>
        <div class="flex-one w-1/3  h-full option-list  items-center flex justify-end mr-4">
          <div
            v-if="!showMobileMenu"
            class="burger"
            @click="showMobileMenu = !showMobileMenu"
          >
            <div class="line" />
            <div class="line  m-2" />
            <div class="line" />
          </div>
          <div
            v-if="showMobileMenu"
            class="close-burger"
            @click="showMobileMenu = !showMobileMenu"
          >
            <div class="line" />
            <div class="line" />
          </div>
        </div>
      </div>

      <div
        class="shadow fixed w-full mobile-menu-items mr-12"
        :class="{ 'mobile-menu-items-active': showMobileMenu}"
      >
        <div class="p-4 w-full">
          <div class="flex-one text-right w-full h-16 flex items-center"
               v-if="isLog === null">
            <div
              @click="login()"
              class="text-xl niveau cursor-pointer font-medium"
            >
              Se connecter
            </div>
          </div>

          <div class="flex-one text-right w-full h-16 flex items-center" v-if="isLog !== null">
            <div
              class="text-xl niveau cursor-pointer font-medium"
              @click="gonext('/home')"
            >
              Mon espace
            </div>
          </div>

          <div class="flex-one text-right w-full h-16 flex items-center" v-if="isLog !== null">
            <div
              class="text-xl niveau cursor-pointer font-medium"
              @click="logout"
            >
             Deconnexion
            </div>
          </div>

          <div class="flex-one text-right w-full h-16 flex items-center">
            <div
              class="text-xl niveau cursor-pointer font-medium"
              @click="actualite()"
            >
              Aide
            </div>
          </div>

          <div class="flex-one text-right w-full h-16 flex items-center">
            <div
              class="text-xl niveau cursor-pointer font-medium"
              @click="faq"
            >
              FAQ
            </div>
          </div>
          <div class="flex-one text-right w-full h-16 flex items-center">
            <div
              class="text-xl niveau cursor-pointer font-medium"
              @click="contact()"
            >
              Contact
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="whatsapp">
      <svg-icon
        name="whatsapp"
        class="h-16 cursor-pointer"
        original
        @click="whatsapp"
      />
    </div>
  </div>
</template>

<script>
import popupSign from '@/components/popup/popupSign'
import logOut from '@/components/popup/logOut'
import popupAgence from '@/components/popup/popupAgence'

export default {
  name: 'TheHeader',
  components: {
    popupSign,
    logOut,
    popupAgence
  },

  data () {
    return {
      showMobileMenu: false,
      activeEvenement: false,
      isLog: null,
      activeLogin: false,
      activDefile: false,
      activeAgence: false,
      broker: null,
      user: null
    }
  },

  mounted () {
    this.isLog = this.$store.getters.tokenState

    if (this.isLog === null && window.location.pathname === '/home') {
      window.location.reload()
    }
    const agence = this.$store.getters.trackAgence
    let user = null

    if (this.isLog !== null) {
      user = this.parseJwt(this.$store.getters.tokenState)
      const expire = this.$store.getters.trackExpirationDate
      const dif = (expire - new Date().getTime()) / (1000 * 3600 * 24)

      if (expire) {
        if (dif < 0) {
          this.$store.dispatch('logout', null)
          this.$router.push('/login')
        }
      } else {
        this.$store.dispatch('logout', null)
        this.$router.push('/login')
      }
    }

    this.user = user
    if (user.meta !== undefined && user.meta.agencyCode !== undefined) {
      this.broker = user.meta.agency
      this.$store.dispatch('saveMyAgence', user.meta)
    }

    if (this.isLog && agence) {
      this.activeAgence = true
    }
  },

  methods: {
    parseJwt (token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      // console.log(JSON.parse(jsonPayload))
      return JSON.parse(jsonPayload)
    },

    retourAgence (answer) {
      this.activeAgence = answer
    },

    defile () {
      this.activDefile = true
    },

    logout () {
      this.$store.dispatch('logout', null)
      window.location.replace('https://oremi.aab.bj')
    },

    retourLogin () {
      this.activeLogin = false
    },

    gonext (index) {
      this.$router.push(index)
    },

    login () {
      this.activeLogin = true
    },

    accueil () {
      this.$store.dispatch('position', 1)
      this.$store.dispatch('saveFirstDevis', null)
      window.location.replace('https://oremi.aab.bj')
    },

    retourLogOut (answer) {
      this.activDefile = answer
    },

    // Zone des redirections

    actualite () {
      window.open('https://www.atlantiqueassurances.bj/', '_blank')
    },

    faq () {
      window.open('https://oremi.aab.bj/faq', '_blank')
    },

    contact () {
      window.open('tel:+22990901818', '_blank')
    },

    whatsapp () {
      window.open('https://wa.me/+22951784862', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.app-header {
  width: 100%;
  background: white;
  z-index: 1;
  height: auto;
  color: #000000;
}
.whatsapp{
  position: fixed;
  bottom: 40px;
  right: 20px;
}
.head1{
  background-color: $base-color;
  .lien{
    background: #8451D2;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
}
.web-header{
  font-size: 18px;
}
.dropdownVip{
  position: absolute;
  right: 300px;
  background-color: #fff;
  width: 10%;
  box-shadow: 0px 1px 2px 0px #0000004f;
  border-radius: 0px 0px 5px 5px;
  .item{
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }
  .item:hover{
    color: blue;
  }
}
.header-container{
  padding-right: 90px;
  padding-left: 80px;
  height: 5.5rem;
}
.shadow{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.diviserH{
  width: 1px;
  height: 2rem;
  background-color: #E9E9E9;
}
.suivre{
  font-weight: normal;
  font-size: 14.5321px;
  line-height: 17px;
  font-family: 'Rubik', sans-serif;
}

.button {
  cursor: pointer;
  padding-top: 10px;
  background-color: $base-color;
  height: 50px;
  font-weight: bold;
  font-family: $font-default;
  font-size: 18px;
  line-height: 18px;
  border: none;
}
.button:hover{
  background-color: #1f7fc8;
}
.mobile-header {
  display: none;
}
.button-section  {
  padding-top: 10px;
  width: 15%;
}
.action {
  max-width: 13%;
}
.action:hover {
  color: $base-color;
}
.burger {
  cursor: pointer;
  .line {
    height: .18rem;
    width: 2rem;
    background-color: $base-color;
  }
}

.close-burger  {
  cursor: pointer;
  .line {
    height: .2rem;
    width: 3rem;
    background-color: $base-color;
  }
  .line:first-child {
    transform: rotate(-40deg);
  }
  .line:last-child {
    transform: rotate(40deg);
  }
}
.mobile-menu-items {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;
}
.mobile-menu-items-active {
  transform: scaleY(1);
}
.premier{
  border-radius: 10px 0px 0px 10px;
}
.second{
  border-radius: 0px 10px 10px 0px;
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .header-container{
    padding: 40px;
    height: 5rem;
  }
  .action{
    margin-right: 2rem !important;
  }
  img{
    height: 3rem;
  }
  .ico{
    height: 2rem;
    width: 2rem;
  }
  .button {
    font-size: 15px;
    height: 2.6rem;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .header-container{
    padding: 20px;
    height: 5rem;
  }
  .action{
    margin-right: 2rem !important;
    font-size: 18px;
  }
  img{
    height: 2rem;
  }
  .button {
    font-size: 14px;
    height: 2.5rem;
  }
  .user{
    display: none;
  }
  .ico{
    height: 2rem;
  }

}

@media only screen and (max-width: 700px) {
  .web-header {
    display: none;
  }
  .mobile-header {
    display: block;
    background-color: white;
    z-index: 999;
  }
  .mobile-menu-items{
    background-color: white;
  }
  .header-container{
    padding: 0px;
    height: 4rem;
  }
  .mobile-header{
    height: 4rem;
  }
  .niveau{
    font-size: 18px;
  }
}
</style>
