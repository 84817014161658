<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div
          class="success-container h-full p-6"
        >
          <div class="titre text-center">A quelle agence appartenez-vous?</div>
          <div class="champ flex items-center pl-4 pr-4 mt-5">
            <svg-icon
              name="search"
              class="h-6 mr-4"
              original
            />
            <input type="text" v-model="search" class="w-4/5 search" placeholder="Rechercher">
          </div>
          <select-liste :option="donnee" @info="retourSelect" v-if="temoin !== search"/>

          <div class="mt-6 flex items-center justify-center" v-if="temoin === search">
            <svg-icon
              name="valid"
              class="h-10 mr-4"
              original
            />
            <div class="w-auto titre">{{search}}</div>
          </div>

          <div class="button continuer w-full mt-6" @click="yes">
            <span v-if="!activeCharge">Continuer</span>
            <spinner color="#ffffff" v-if="activeCharge"/>
          </div>
          <div class="text-center w-full mt-4 cursor-pointer" @click="fermer">Je n'appartient pas à une agence</div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import selectListe from '../helper/add/selectListe'
import broker from '@/components/cedro/jsonWord/intermediates.json'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'
import spinner from '@/components/helper/add/spinner'

export default {
  name: 'Success',
  components: { PopupBase, selectListe, spinner },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      allBroker: [],
      search: null,
      temoin: '',
      activeCharge: false
    }
  },
  computed: {
    donnee: function () {
      let retour = this.allBroker

      if (this.search !== null) {
        let authorNameSearchString = this.search
        authorNameSearchString = authorNameSearchString.toLowerCase()
        retour = retour.filter((item) => {
          if (item !== undefined && (item.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
            return item
          }
        })
      }
      return retour
    }
  },
  created () {
    this.trackAgence()
  },

  methods: {
    retourSelect (answer) {
      this.search = answer
      this.temoin = answer
    },

    trackAgence () {
      for (const item in broker) {
        this.allBroker.push(broker[item].label)
      }
    },

    fermer () {
      this.$store.dispatch('saveAgence', false)
      this.$emit('oga', false)
    },

    yes () {
      for (const item in broker) {
        const name = broker[item].label

        if (this.search === name) {
          this.activeCharge = true
          http.post(apiroutes.baseURL + apiroutes.saveAgence, {
            agency: broker[item]
          })
            .then(response => {
              this.activeCharge = false
              this.$store.dispatch('saveMyAgence', response.data.meta)
              this.$store.dispatch('saveAgence', false)
              this.$emit('oga', false)
            })
            .catch(error => {
              window.location.reload()
              this.activeCharge = false
              console.log('erreur')
              console.log(error)
            })
        }
      }
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 50%;
    }
    .titre{
      font-size: 24px;
      color: black;
      font-weight: bold;
    }
    .svg-fill{
      fill: white;
    }
    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      padding: 0px ;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }

    .champ{
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 10px;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      font-family: $rubik;
      color: #909090;
      height: 70px;
    }

    .button{
      background-color: $base-color;
      height: 57.36px;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: $white;
      border: 1px solid #0269AD;
      box-sizing: border-box;
      border-radius: 10px;
    }

    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre{
        font-size: 18px;
      }
      .bloc1{
        width: 100%;
      }
      .bloc2{
        width: 100%;
        font-size: 18px;
      }
      .button{
        width: 100%;
        font-size: 16px;
      }
      .cardinal{
        width: 100%;
      }
    }
</style>
